<template>
  <div class="cont_list_main" :class="{ loading: loading && items.length, horizontal_items: horizontalItems }">
    <template v-for="(z, ind) in items">
      <span v-if="showAll || showItemLimit > ind" :key="z.id + 'i' + z.zid + 'i' + ind" class="po-re" :class="{ horizontal_sub: horizontalItems }">
        <span v-if="z.disTp" :class="{ nano_mode: nanoMode, dis_tp: true }" :key="'dis' + z.zid">{{ z.disTp.name_public }}</span>
        <div
          :id="z.id"
          class="cont_zar_list"
          @click.exact="clickedItem({ obj: z.obj, zid: z.zid })"
          @click.exact.right.prevent="clickedRight(z)"
          @click.exact.ctrl="newTab(z.obj, z.zid)"
          @click.middle.exact.stop.prevent="newTab(z.obj, z.zid)"
          :class="{
            newly_listed_cont: newlyListed(ind) && !miniMode && !nanoMode,
            portable: cusFieldAllowed && cusFieldAllowed.length,
            witem: z.zid == witem.zid,
            sep: ind % pageLimit === 0 && ind > 0,
            collected: $route.name == 'home' && collectMapped.includes(z.zid),
            mini_mode: miniMode,
            nano_mode: nanoMode,
            cont_spo: z.spo,
            disabled: z.report >= configs.zar_report_disabled_min,
            disabled: z.dis,
          }"
        >
          <div v-if="userMenu" class="object_name">{{ getObjName(z.obj) }}</div>
          <ui-icon v-if="z.report && !nanoMode" class="zar_report" name="i-report" />
          <user-zar-action v-if="userMenu && !z.dis" class="userMenu" :style="{ zIndex: 100 - ind }" :zid="z.zid" :obj="z.obj" :rdate="z.rdate" />
          <b v-if="ind % pageLimit === 0 && ind > 0" class="spacer"></b>
          <div class="cont_image">
            <!-- <div class="cont_image" :class="{ ani_pop: isMobile }"> -->
            <template v-for="(p, pi) in z.pinfo">
              <div v-if="p.pcomp_tp == 3" class="cont_image" :key="'image' + pi">
                <span v-if="z.uid == user.uid && !nanoMode && !miniMode && !z.spo" :class="{ my_zar: z.uid == user.uid, my_zar2: z.uid != user.uid && z.dev_id == devid ? true : false, mini_mode: miniMode }">Миний зар</span>
                <span v-if="p.total > 2" class="image_total">
                  <ui-icon v-if="z.img_quality && z.img_quality == 3" name="i-hd" size="xl" :style="{ top: '2px' }" />
                  <ui-icon v-else name="i-image" size="l" /><span>{{ p.total }}</span>
                </span>
                <div class="cont_thumbnail">
                  <img
                    :id="'img' + z.zid"
                    class="photo"
                    :class="[newlyListed(ind) && !miniMode && !nanoMode ? 'newly_listed' : getImageSizeClass(p.total), miniMode && 'mini_mode', nanoMode && 'nano_mode', z.spo && 'spo']"
                    v-if="p.pcomp_tp == 3 && p.val && p.val.length"
                    :src="apiUrl + '/media/zar/' + z.obj + '/' + z.zid + '/' + p.id + '/' + (newlyListed(ind) || (isMobile && !nanoMode) ? 'm' : 't') + p.val[0].id + '.jpg'"
                  />
                  <span class="badge" v-if="z.spo"><ui-icon name="i-badge" :size="nanoMode || miniMode ? 'xxl' : 'xxxxl'"/></span>
                  <span class="magnify_photo" v-if="!nanoMode && !miniMode" @click.stop="imagePreviewList ? clickedImage(z.obj, z.zid, p) : clickedItem({ obj: z.obj, zid: z.zid })"><ui-icon name="i-fullscreen" size="xxl"/></span>
                </div>
              </div>
            </template>
          </div>
          <div class="cont_spec">
            <div v-if="z.spo && !nanoMode && !miniMode" class="spo_text">Онцлох зар</div>
            <template v-for="(p, pi) in z.pinfo">
              <br v-if="fieldAllowed(p.ptype) && p.newLine" :key="'newline' + pi" />
              <span
                :key="'spec' + pi"
                :style="{ display: !p.val && 'none', marginTop: p.ptype == 'price' && (!miniMode && !nanoMode ? '10px' : '6px') }"
                :class="{
                  [p.ptype + (p.val && p.val['com'] ? ' ' + p.ptype + '_common' : '')]: p.val,
                  [p.ptype + '_dep_' + p.dep + (p.val && p.val['com'] ? ' ' + p.ptype + '_common_dep_' + p.dep : '')]: p.val,
                  [p.ptype + '_obj_' + z.obj]: p.val,
                  hide: !fieldAllowed(p.ptype),
                  mini_mode: miniMode,
                  nano_mode: nanoMode,
                  spo: z.spo,
                  disable_icon: p.val ? p.val['icon'] : '',
                  actSort: activeSort && p.id == activeSort.id,
                }"
              >
                <span v-if="fieldAllowed(p.ptype) && p.pcomp_tp != 3">
                  <span v-if="p.pcomp_tp == 6">
                    <ui-icon v-if="isMobile" class="icon icon_video" name="i-video" size="xl" />
                    <ui-video-player v-if="p.val && !isMobile" :src="apiUrl + '/media/zar/' + z.obj + '/' + z.zid + '/' + p.id + '/' + p.val" maxWidth="200px" loop maxHeight="150px" muted autoPlay :controls="false" />
                  </span>
                  <ui-icon v-if="p.pcomp_tp == 8" class="icon" name="i-youtube" size="xl" />
                  <span v-if="![3, 6, 5].includes(p.pcomp_tp) && p.val">
                    <ui-icon class="icon" v-if="p.val['icon'] && p.val['icon'] != 'empty'" :name="p.val['icon']" />
                    <span v-if="!nanoMode && p.ptype == 'price' && z.old_price" class="mr-1 old_price">
                      <span v-if="p.conver" v-html="converters(p.conver, z.old_price)" class="value" />
                      <span v-else class="value">{{ z.old_price }} {{ p.unit }}</span>
                    </span>

                    <span v-if="p.conver" v-html="p.val" class="value" />
                    <span v-if="!p.conver && !p.val['icon']" class="value" :class="{ ['saw_' + (p.ptype == 'title' && viewedTime(z.zid))]: p.ptype == 'title' && viewedTime(z.zid) }">
                      {{ p.pcomp_tp == 1 ? p.val["name"] : p.val }}<ui-icon v-if="!nanoMode && p.ptype == 'title' && favsMapped.includes(z.zid)" name="i-bookmark2" class="faved" size="l" />
                      <span v-if="p.ptype == 'title' && !z.spo" :set="(tl = checkExpireTime(z.obj, z.rdate))"></span>
                      <span v-if="p.ptype == 'title' && !z.spo" class="time_since" :class="{ expired: tl && tl.status == 'expired' }">
                        <a :href="baseUrl + '/zar/view/?o=' + z.obj + '&z=' + z.zid" v-on:click.prevent="">{{ z.date }}</a>
                      </span>
                      <span v-if="p.ptype == 'title' && !z.spo && tl.status == 'aboutExpire'" class="aboutExpire">{{ tl.leftTime == 0 ? "хэдэн цаг үлдсэн" : tl.leftTime + " өдөр үлдсэн" }}</span>

                      <div v-if="!nanoMode && z.nearLoc && p.ptype == 'title'" :class="'pulse_container' + z.nearLoc">
                        <div class="po-ab">
                          <div class="dot"></div>
                          <div class="pulse"></div>
                        </div>
                      </div>
                    </span>

                    <span v-if="(p.unit && !p.val['icon'] && p.ptype != 'price') || (p.ptype == 'price' && p.conver != 'shift_million' && p.conver != 'shift_billion')" class="unit">{{ p.unit }}</span>
                  </span>
                  <span v-if="p.pcomp_tp == 5">
                    <span v-for="(v, vi) in p.val" :key="'multi' + vi" class="tag">
                      {{ v.name }} <span v-if="p.unit">{{ p.unit }}</span>
                    </span>
                  </span>
                </span>
              </span>
            </template>
            <div class="zar_info" v-if="userMenu">
              <span v-if="z.view"><ui-icon name="i-view2" size="l" /> {{ z.view }}</span>
              <span v-if="z.view_phone"><ui-icon name="i-phone" size="l" /> {{ z.view_phone }}</span>
              <span v-if="z.favs"><ui-icon name="i-bookmark" size="l" /> {{ z.favs }}</span>
              <span v-if="z.boffer"><ui-icon name="i-auto" size="l" /> {{ z.boffer }}</span>
            </div>
          </div>

          <div v-if="ind + 1 != items.length" class="cont_spacer" />
        </div>
      </span>
    </template>
    <div v-if="!showAll && items.length >= showItemLimit" class="ta-c link ml-1 mt-1" @click="showAll = true">цааш...</div>

    <div v-if="!loading && !items.length" class="loading_status">Зар олдсонгүй...</div>
    <div v-if="!items.length"></div>
    <div v-if="loading && !items.length && showPlaceholder">
      <h4 class="title placeholder"></h4>
      <br />
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <br />
      <h4 class="title placeholder"></h4>
      <br />
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <br />
      <h4 class="title placeholder"></h4>
      <br />
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import userZarAction from "@/comp/user/zarAction.vue";
import uiVideoPlayer from "@/comp/ui/videoPlayer.vue";

export default {
  name: "zar-list",
  components: {
    userZarAction,
    uiVideoPlayer,
  },
  props: {
    userMenu: Boolean,
    prevZar: String,
    zarFocus: String,
    items: { type: Array, default: () => [] },
    pageLimit: Number,
    cusFieldAllowed: Array,
    showLimit: { type: [Number, Boolean] },
    linkDirect: {
      type: Boolean,
      default: false,
    },
    miniMode: {
      type: Boolean,
      default: false,
    },
    nanoMode: {
      type: Boolean,
      default: false,
    },
    loading: Boolean,
    activeSort: Object,
    enableNewlyListed: { type: Boolean, default: false },
    horizontalItems: Boolean,
    viewMain: Boolean,
    imagePreviewList: { type: Boolean, default: false },
  },
  data() {
    return {
      preTimer: null,
      showPlaceholder: true,
      showItemLimit: this.showLimit,
      showAll: this.showLimit ? false : true,
    };
  },
  methods: {
    clickedImage(obj, zid, p) {
      this.$emit("clickedImage", { obj, zid, p });
    },
    newlyListed(ind) {
      return this.enableNewlyListed && ind < 3;
    },
    getObjName(obj) {
      if (!this.catData) return;

      let found = this.catData.obj.find((e) => e.id == obj);
      return found ? found.name : "not found";
    },
    getImageSizeClass(total) {
      return total < 5 ? "small" : total < 10 ? "mid" : "big";
    },
    newTab(obj, zid) {
      let route = this.$router.resolve({ name: "zarView", query: { o: obj, z: zid } });
      window.open(route.href, "_blank");
    },
    viewedTime(zid) {
      let time = "";
      this.viewHistory.find((f) => {
        if (f.zid == zid) {
          let dur = Date.now() - f.date;
          switch (true) {
            case dur <= 1000 * 60 * 60 * 24:
              time = "today";
              break;
            case 1000 * 60 * 60 * 24 < dur && dur <= 1000 * 60 * 60 * 24 * 7:
              time = "week";
              break;
            case 1000 * 60 * 60 * 24 * 7 < dur:
              time = "week_after";
              break;
            default:
              time = "";
          }
          return time;
        }
      });
      return time;
    },

    fieldAllowed(ptype) {
      if (this.miniMode && ptype == "body") return false;
      if (!this.cusFieldAllowed) return true;
      return this.cusFieldAllowed.includes(ptype) ? true : false;
    },

    clickedItem({ obj, zid }) {
      // console.log("clicked:", obj, zid, this.witem);

      this.$store.commit("returnPos", window.pageYOffset || document.documentElement.scrollTop);
      // if (this.linkDirect) {
      // if (this.scr == 1 && this.viewMain) this.$bus.emit("closeDrawer", "viewItem");

      if (this.witem && this.witem.obj == obj && this.witem.zid == zid && this.$route.name == "zarView") return this.$scrollTo("#header", 500);
      this.$router.push({ name: "zarView", query: { o: obj, z: zid } });

      // WORKING DISABLED DRAWER ZAR VIEW
      // if (this.scr == 1) {
      //   this.$bus.emit("openDrawer", { name: "viewItem", obj, zid });
      // } else {
      //   if (this.witem && this.witem.obj == obj && this.witem.zid == zid && this.$route.name == "zarView") return this.$scrollTo("#header", 500);
      //   this.$router.push({ name: "zarView", query: { o: obj, z: zid } });
      // }

      // }
      this.$emit("clickedItem", { obj, zid });
    },

    clickedRight(ob) {
      this.$emit("clickedRight", ob);
    },
    checkReachBottom() {
      let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight;
      if (bottomOfWindow) this.$emit("reachedBottom", this.reachedBottom);
    },
    checkExpireTime(obj, itemDate) {
      if (!this.catData || !itemDate) return false;

      let object = this.catData.obj.find((e) => e.id == obj);
      if (!object || !object.configs) return false;

      let expireTime = JSON.parse(object.configs)["expire_time"];
      if (!expireTime) return false;

      let now = Math.floor(Date.now() / 1000);
      let day = 60 * 60 * 24;

      let status = false;
      if (itemDate < now - day * expireTime) status = { status: "expired" };
      else {
        let leftTime = itemDate - (now - day * expireTime);
        if (leftTime < day * 3) status = { status: "aboutExpire", leftTime: Math.floor(leftTime / day) };
      }

      return status;
    },
  },

  computed: {
    sw() {
      let w = this.$store.state.swidth;
      return w > 694 ? 694 : w - 30;
    },
    catData() {
      return this.$store.state.zar.catData;
    },
    viewHistory() {
      return this.$store.state.user.viewHistory;
    },
    scr() {
      return this.$store.getters.scr;
    },
    configs() {
      return this.$store.state.configs;
    },
    collect() {
      return this.$store.state.user.collect;
    },
    collectMapped() {
      return this.collect.map((e) => e.zid);
    },
    favsMapped() {
      return this.$store.state.user.favs.map((e) => e.zid);
    },
    witem() {
      return this.$store.state.zar.witem;
    },
    user() {
      return this.$store.state.auth.status.logged ? this.$store.state.auth.user : false;
    },
    devid() {
      return this.getLocal("devid", true);
    },
    openDrawers() {
      return this.$store.state.openDrawers;
    },
  },
  watch: {
    // items(itemsNew, itemsOld) {
    //   if (itemsOld.length) this.$scrollTo("#cont_list" + this._uid, 500);
    // },
  },
  mounted() {
    // console.log("comp uid:", this._uid, " frist item:", this.items[0]);

    // setTimeout(() => (this.showPlaceholder = true), 200);
    this.$bus.on("viewZar", this.clickedItem);
    window.addEventListener("scroll", this.checkReachBottom);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_list_main {
  /* opacity: 1;
  transition: opacity 50ms; */
}
.cont_list_main.loading {
  /* opacity: 0;
  transition: opacity 3000ms; */
}
.cont_zar_list {
  padding: 2.5vh 0;
  position: relative;
  line-height: 125%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* width: 90vw;
  max-width: 600px; */
  /* width: 100%; */
  max-width: 100vw;
  /* overflow-x: hidden; */
  transition: all 100ms;
  box-sizing: border-box;
  border: solid 1px transparent;
  align-content: center;
  align-self: center;
  transition: all 150ms;
}

.cont_zar_list.mini_mode {
  padding: 1vh 0;
}
.cont_zar_list.nano_mode {
  width: min-content;
  display: inline-flex;
  flex-wrap: wrap;
  padding: 5px;
  position: relative;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  /* margin-right: 10px; */
  margin-bottom: 10px;
}

.horizontal_sub .cont_zar_list.nano_mode {
  margin-right: 0px;
}

.newly_listed_cont {
  display: block !important;
}

.newly_listed_cont .photo {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 350px;
  /* animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shine_on;
  animation-timing-function: linear; */
}

/* @media only screen and (max-width: 700px) {
  .newly_listed_cont .photo {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 350px;
  }
} */

.newly_listed_cont .cont_image {
  margin-left: 0px !important;
  /* background-color: #f1f1f1;
  width: 100% !important;
  text-align: center;
  margin-bottom: 20px; */
}

.nano_mode .cont_image {
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nano_mode .cont_spacer {
  display: none;
}

.cont_thumbnail {
  position: relative;
}

.magnify_photo img {
  filter: invert(100%) sepia(3%) saturate(20%) hue-rotate(89deg) brightness(103%) contrast(100%);
}

.magnify_photo {
  position: absolute;
  bottom: 0px;
  right: 0px;
  /* background-color: #3771b4; */
  padding: 15px;
}

.nano_mode .title {
  font-size: 12tpx;
  display: block;
  margin-top: 3px;
  text-align: center;
  /* font-stretch: 95%; */
  font-weight: 360;
  /* color: #666; */
  width: fit-content;
}

.nano_mode .time_since {
  display: none;
}

.nano_mode .price {
  position: inherit;
  float: right !important;
  transform: translateY(20%);
  color: #000;
  /* background-color: transparent; */
  font-size: 14px;
  padding-top: 5px;
  font-weight: 500;
  /* margin: 0 auto; */
}

.object_name {
  font-size: 12px;
  width: fit-content;
  border-radius: 3px;
  /* border: solid 1px #ccc; */
  line-height: 100%;
  padding: 2px 3px 1px 3px;
  position: absolute;
  top: -10px;
  color: #363636;
  background: linear-gradient(90deg, rgb(236, 255, 233) 0%, rgba(255, 255, 255, 1) 100%);
}

.horizontal_items {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 0px;
  width: 100vw;
  max-width: 100%;
  background-color: #f1f1f1 !important;
  -webkit-overflow-scrolling: touch;
  padding-top: 7px;
}
.cont_list_main::-webkit-scrollbar {
  display: none;
}

.horizontal_sub {
  flex: 0 0 auto;
}

.cont_spacer {
  position: absolute;
  transform: translateX(50%);
  right: 50%;
  bottom: -1px;
  width: 60%;
  height: 1px;
  border-bottom: dashed 1px var(--color_sep);
}

.collected {
  background-color: #e2f3ff;
}

.cont_zar_list.collected:hover {
  background-color: #e2f3ff;
}

.dis_tp {
  position: absolute;
  background-color: #fffbca;
  right: 10px;
  bottom: 10px;
  transform: translate(-50%, -50%);
  padding: 2px 7px;
  line-height: 1.6em;
  border-radius: 5px;
  border: dashed 1px #ccc;
  z-index: 5;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.nano_mode.dis_tp {
  font-size: 10px;
  padding: 0px 3px;
}

.actSort {
  background-color: rgb(255, 255, 162) !important;
}
.expired {
  background-color: #ffc81557;
  padding: 4px 5px 2px 5px;
  line-height: 1;
}

.portable {
  margin: 0;
}
.faved {
  position: relative;
  display: inline-block;
  top: 3px;
  left: 4px;
  margin-right: 3px;
  filter: invert(35%) sepia(97%) saturate(2698%) hue-rotate(2deg) brightness(104%) contrast(105%);
}
.cont_image {
  position: relative;
  margin-right: 4px;
  width: fit-content;
}
.cont_spec {
  text-align: left;
  padding-right: 5px;
  padding-left: 8px;
  /* width: 100%; */
}
.nano_mode.witem {
  background-color: var(--color_selected);
  border-radius: 5px;
}

.nano_mode.witem .price {
  background-color: var(--color_selected);
}
/* .nano_mode.witem span {
  color: #fff !important;
} */

.witem {
  background-color: #f1f1f1;
  /* background: url("~@/assets/images/watching_now.png") repeat; */
}
.witem:hover {
  background-color: #f1f1f1 !important;
}

.nano_mode.witem:hover {
  background-color: var(--color_selected) !important;
}

.my_zar,
.my_zar2 {
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 10px;
  position: absolute;
  line-height: 100%;
  z-index: 10;
  font-weight: var(--base_weight);
  letter-spacing: 0.03em;
  left: -5px;
  top: -5px;
  /* border: solid #ccc 1px; */
}

/* .my_zar.mini_mode,
.my_zar2.mini_mode {
  border-radius: 0px;
  margin: -5px 0 0 0px;
} */

.userMenu {
  position: absolute;
  width: fit-content;
  right: 100px;
  top: 40%;
  z-index: 0;
}

.my_zar {
  background-color: #a9fffb;
  /* background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(241, 241, 241, 1) 100%); */
}
.my_zar2 {
  background-color: #3771b4;
  display: none;
}

.cont_zar_list.witem.mini_mode {
  /* background-color: #f1f8ff; */
}
.cont_zar_list.witem {
  /* background-color: #f1f8ff; */
}

.sep {
  margin-top: 30px;
}

.relative {
  position: relative;
  background-color: #af5454;
}

.nano_mode .image_total {
  border-top-right-radius: 3px;
}

.image_total {
  color: #fff;
  position: absolute;
  /* text-shadow: 0px 1px 1px rgba(0, 0, 0, 1); */
  opacity: 1;
  top: 0px;
  right: 0px;
  z-index: 1;
  padding: 3px 4px 0px 8px;
  line-height: 100%;
  letter-spacing: -0.04em;
  font-weight: 500;
  font-size: 13px;
  background: rgba(0, 0, 0, 0.2);
  /* font-stretch: 85%; */
}
.image_total span {
  position: relative;
  top: -3px;
  left: -2px;
}
.image_total img {
  position: relative;
  top: 0px;
  left: -5px;
  filter: invert(100%) sepia(3%) saturate(20%) hue-rotate(89deg) brightness(103%) contrast(100%);
}
.photo {
  transition: all 100ms;
  transform: rotate(0deg);
}
.photo:active {
  transform: scale(1);
}
.photo.spo {
  max-width: 100% !important;
  /* animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shine_on;
  animation-timing-function: linear; */
  /* filter: saturate(1.5) brightness(1.8); */
}
.nano_mode .small,
.nano_mode .mid,
.nano_mode .big {
  border-radius: 3px;
}

.small,
.mid,
.big {
  /* float: left; */
  background-position: 0px 0px;
  background-repeat: repeat;
}
.small {
  max-width: 300px;
  max-height: 250px;
}
.mid {
  max-width: 300px;
  max-height: 250px;
}
.big {
  max-width: 300px;
  max-height: 250px;
}

.small.mini_mode {
  max-width: 240px;
  max-height: 160px;
}

.mid.mini_mode {
  max-width: 240px;
  max-height: 160px;
}

.big.mini_mode {
  max-width: 240px;
  max-height: 160px;
}

.small.nano_mode {
  max-width: 140px;
  max-height: 100px;
}

.mid.nano_mode {
  max-width: 140px;
  max-height: 100px;
}

.big.nano_mode {
  max-width: 140px;
  max-height: 100px;
}

.saw_today {
  font-weight: var(--base_weight) !important;
  /* color: #2d5592 !important; */
}

.saw_week {
  font-weight: 480 !important;
  /* color: #2d5592 !important; */
}
.saw_week_after {
  /* color: #2d5592 !important; */
  font-weight: var(--base_weight) !important;
}

i {
  font-style: normal;
}

.zar_info {
  font-size: 13px;
  display: flex;
  gap: 10px;
  background-color: #f1f1f1;
  width: fit-content;
  padding: 0 8px;
  border-radius: 3px;
  margin-top: 5px;
}
.zar_info img {
  position: relative;
  top: 3px;
}

.time_since {
  font-size: 12px;
  display: inline-block;
  font-weight: var(--base_weight);
  margin-left: 5px;
  font-stretch: 95%;
  letter-spacing: 0.03em;
  font-family: Segoe_UI_Historic, system-ui;
}

.time_since a {
  color: #8d8d8d;
}

.time_since.expired a,
.time_since.aboutExpire a {
  color: #444;
}

.title.time_since.mini_mode {
  font-size: 11px;
}
.image_hd {
  right: 3px;
  position: absolute;
  filter: invert(100%) sepia(96%) saturate(15%) hue-rotate(212deg) brightness(104%) contrast(104%);
  z-index: 5;
}

.hide {
  display: none !important;
}

.zar_report {
  position: absolute;
  right: 12px;
  bottom: 9px;
  opacity: 0.4;
}
.cont_spo {
  background: radial-gradient(circle, #befeff 0%, #ffffff 100%);
}

.spo_text {
  font-weight: inherit;
  color: #000;
  font-size: 11px;
  background: radial-gradient(circle, #befeff 0%, #ffffff 100%);

  width: fit-content;
  font-stretch: semi-condensed;
  font-weight: var(--base_weight);
  text-transform: uppercase !important;
}

.badge {
  position: absolute;
  top: -8px;
  left: 0px;
  filter: drop-shadow(0 0px 5px rgba(255, 255, 255, 0.5));
}

.placeholder {
  background-color: #444;
  min-height: 12px;
  border-radius: 3px;
  opacity: 0.1;
  min-width: 10px;
  animation: fading 1.5s infinite;
}
.post {
  width: 100%;
  margin: 10%;
  padding: 10px 20px;
  /* box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 2px 0 rgba(63, 63, 68, 0.15); */
}
.post-image {
  width: 100%;

  height: 400px;
  border-radius: 0;
}
.title {
  width: 50%;
  height: 24px;
}
.text {
  width: 80%;

  height: 14px;
}

@keyframes fading {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.1;
  }
}

/* COMMON -------------------------------------------------------------------------------------------------------*/

.title {
  color: var(--color_link);
}

.title.nano_mode .value {
  font-size: 12px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3em;
  /* color: #444 !important; */
  /* font-weight: 400 !important; */
}

.newly_listed_cont .title .value {
  font-size: 15px;
}

.title .value {
  display: inline-block;
  line-height: 1.4em;
  text-transform: lowercase;
  font-weight: 700;
  word-wrap: anywhere !important;

  /* margin-bottom: 10px !important; */

  /* font-stretch: normal; */
}

.title .value:first-letter {
  text-transform: capitalize;
}

.title.mini_mode .value {
  font-size: 13px;
}

.body {
  margin-top: 10px;
  display: block;
  word-wrap: anywhere;
  white-space: pre-line;
  padding: 0 5px 0 4px;
  /* quotes: "\201C""\201D""\2018""\2019"; */
  /* max-height: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
  /* margin: 5px 0; */
}
.body .value {
  font-size: 13px;
  opacity: 0.8;
  font-stretch: 140%;
}

.newly_listed_cont .body {
  -webkit-line-clamp: 16; /* number of lines to show */
  line-clamp: 16;
}
.newly_listed_cont .body .value {
  opacity: 1;
}

/* .body::before {
  content: open-quote;
  font-weight: bold;
  font-size: 18px;
  color: #bbbbbb;
}

.body::after {
  content: close-quote;
  font-weight: bold;
  font-size: 16px;
  color: #bbbbbb;
} */

/* .body .value {
  color: #666;
} */

.spec_no_dot {
  font-size: 12px;
  padding-right: 7px;
  display: inline-block;
  color: #444;
}

.spec {
  font-size: 12px;
  padding-right: 7px;
  display: inline-block;
  color: #444;
}

.spec::before {
  content: ".";
  font-size: 18px !important;
  position: relative;
  top: -3px;
  padding: 0 3px 0 0px;
  color: #777;
}

.spec .icon {
  position: relative;
  top: 2px;
}

.disable_icon::before {
  content: none;
}

.price {
  font-size: 18px;
  letter-spacing: -0.01em;
  font-weight: 500;
  width: fit-content;
  color: #000;
  line-height: 1;
  display: block;
  /* font-stretch: 150%; */

  /* margin-top: 10px; */
  /* position: absolute; */
  /* right: 0; */
  /* top: 20px; */
  /* bottom: 23px; */
  background-color: #f1f1f1;
  padding: 6px 8px 4px 8px;
  /* border-radius: 3px; */

  /* border: dashed 2px #ffecb1; */
  /* background: linear-gradient(90deg, #ffecb1 0%, transparent 100%); */
}

.price.mini_mode {
  top: 85%;
  font-size: 14px;
  font-weight: 550;
  border-radius: 0px;
}

.price .unit {
  font-weight: var(--base_weight);
  font-size: 14px;
  color: #5f5f5f;
}

.made_for {
  display: block;
}

.youtube .value {
  display: none;
}

.icon {
  margin-right: 7px;
  display: table-cell;
}

.youtube,
.video {
  display: table-cell;
}
.youtube .value {
  display: none;
}

.barter {
  position: relative;
  top: -1px;
  display: none;
}

.barter:has(img) {
  display: table-cell;
}

.barter .icon {
  width: 16px;
  height: 16px;
  filter: invert(26%) sepia(97%) saturate(3014%) hue-rotate(207deg) brightness(103%) contrast(106%);
  /* animation: spin 2s linear infinite; */
}

.unit {
  font-size: 12px;
  margin-left: 2px;
}

.tugrug {
  font-weight: inherit;
  /* color: #000000; */
  margin-right: 2px;
  font-size: smaller;
  font-stretch: 150%;
  font-weight: 300;
  /* opacity: 0.4; */
}

.make {
  display: inline-block;
  padding-right: 5px;
  /* font-weight: 350; */
  color: #444;
  /* text-transform: uppercase; */
  /* font-size: 13px !important; */
}

.model {
  padding-right: 5px;
  display: inline-block;
  /* font-size: 15px; */
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  /* font-stretch: 100%; */
  text-align: center;
  width: fit-content;
  /* white-space: nowrap; */
  /* width: 0vw; */
  /* vertical-align: middle; */
  /* width: max-content; */
  /* word-break: keep-all; */
  /* font-family: finland, serif; */

  /* font-family: podkova, serif;
  letter-spacing: -0.02em; */
}

.model.spo {
  font-size: 14px !important;
  font-weight: 500;
}

.year {
  display: inline-block;
  /* font-size: 14px; */
  color: #000;
  white-space: nowrap;

  /* width: 0vw; */
  /* vertical-align: middle; */
}

.year .unit {
  font-weight: inherit;
  margin-left: -2px;
  color: var(--color_body);
}

.year .value {
  font-weight: 500;
  /* font-family: podkova, serif; */
}
.condition {
  display: inline-block;
}

.condition .icon {
  position: relative;
  width: 16px;
  height: 16px;
  left: 2px;
  padding-right: 0px;
  top: 3px;
  margin-right: 5px;
}

.types {
  display: inline-block;
  /* font-size: 13px; */
  padding-right: 7px;
  font-weight: var(--base_weight);
}

.mgl_time,
.make_time {
  border-radius: 2px;
  padding-right: 7px;
  font-size: 13px;
  display: inline-block;
}

.mgl_time {
  color: rgb(0, 161, 0);
}

.mgl_time .unit {
  display: none;
}

.make_time,
.make_time .unit {
  color: #005bc4;
}

.usage {
  font-size: 11px;
  display: inline-block;
  line-height: 1;
  border: solid 1px #ccc;
  padding: 3px 4px 1px 4px;
  border-radius: 3px;
  background-color: #faf6ff;
  margin-right: 7px !important;
  text-align: center;
}

.cc_dep_2 {
  margin-right: 7px !important;
}

.usage_obj_79 {
  display: table-cell;
}

.stage_total {
  display: inline-block;
  font-size: 10px;
}
.stage {
  display: inline-block;
  padding-right: 7px;
  font-size: 12px;
}
.stage::before,
.stage_total::before {
  content: ".";
  font-size: 18px !important;
  position: relative;
  top: -3px;
  padding: 0 3px 0 0px;
}
.stage_total::after {
  content: "/ ";
  font-size: 8px !important;
  position: relative;
  top: -1px;
  padding: 0 3px;
}
.stage_total .unit {
  display: none;
}
.stage .unit {
  font-size: 11px;
}
.price_unit {
  font-size: 13px;
  width: fit-content;
  display: block;
}

/* .tag {
  display: inline-block;
  border: solid 1px #d3d3d3;
  padding: 2px 5px 0px 5px;
  border-radius: 15px;
  cursor: pointer;
  margin: 2px 4px 2px 0;
  user-select: none;
  font-size: 12px;
  line-height: 1;
} */

.tag {
  display: inline-block;
  padding: 4px 7px 2px 7px;
  cursor: pointer;
  margin: 2px 4px 5px 0;
  line-height: 1;
  transition: all 0.1s;
  border-radius: 15px;
  user-select: none;
  position: relative;
  font-size: 12px;
  background: #e2ffac;
  border: solid 1px #ccc;
  font-weight: 450;
  text-align: center;
  /*white-space: nowrap;*/
}

.tovch {
  display: inline-block;
  background-color: #f1f1f1;
  padding: 2px 5px;
  line-height: 1;
  border-radius: 8px;
  font-size: 13px;
  margin-right: 7px;
}
.price_unit.mini_mode {
  /* top: 85%;
  margin-top: -43px; */
}

.price_unit .value {
  color: #000;
}
.price_unit .unit {
  color: #6d6d6d;
}
.punit {
  font-weight: inherit;
}

.town {
  padding-left: 5px;
  display: table-cell;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}
.town.spo {
  font-size: 14px;
}
.area {
  display: table-cell;
  font-size: 13px;
}

.icon_video {
  animation: blinker 1s linear infinite;
}

/* .price_obj_13 .unit,
.price_obj_26 .unit,
.price_obj_27 .unit,
.price_obj_28 .unit,
.price_obj_29 .unit,
.price_obj_30 .unit,
.price_obj_31 .unit,
.price_obj_32 .unit,
.price_obj_33 .unit,
.price_obj_34 .unit,
.price_obj_35 .unit,
.price_obj_36 .unit,
.price_obj_37 .unit,
.price_obj_39 .unit,
.price_obj_40 .unit,
.price_obj_41 .unit,
.price_obj_42 .unit,
.price_obj_24 .unit,
.price_obj_23 .unit,
.price_obj_22 .unit,
.price_obj_8 .unit,
.price_obj_21 .unit {
  display: none;
} */

/* DEPARTMENT MOBILE -------------------------------------------------------------------------------------------------*/

/* DEPARTMENT COMPUTER -------------------------------------------------------------------------------------------------*/

/* DEPARTMENT AUTO -------------------------------------------------------------------------------------------------*/

.year_dep_2 {
  /* font-size: 15px;
  font-weight: 400; */
}
.year_dep_2 .unit {
  display: none;
}
.imp_year_dep_2 {
  display: inline-block;
  padding-left: 11px;
  font-size: 12px;
  font-weight: var(--base_weight);
}
.imp_year_dep_2 .unit {
  display: none;
}
.imp_year_dep_2::before {
  content: "/";
  position: absolute;
  margin-left: -7px;
  font-size: 8px;
  font-weight: 500;
  /* color: #adadad; */
}
/* .year_dep_2::before {
  content: "|";
  position: relative;
  font-size: 8px;
  font-weight: 200;
  top: -2px;
  padding: 0 6px 0 0px;
} */
.make_dep_2:has(img) {
  position: relative;
  width: 20px;
}
.make_dep_2 img {
  height: 17px;
  position: absolute;
  top: -1px;
  left: 1px;
}

.make_dep_2 {
  margin-bottom: 2px;
}

.wheel_size_width {
  display: table-cell;
}

.tire_size_obj_13 {
  display: table-cell;
}

.tire_size_height .unit,
.tire_size_width .unit,
.tire_size_obj_17 .unit {
  display: none;
}

.tire_size_height .value,
.tire_size_width .value,
.tire_size .value {
  font-weight: 500;
}
.tire_size {
  padding-right: 7px;
}

.tire_size_height::before,
.tire_size_obj_17:before {
  content: "/";
  font-size: 10px;
  position: relative;
  top: -1px;
  padding: 0 2px;
}
@media only screen and (min-width: 701px) {
  .cont_zar_list:hover {
    cursor: pointer;
    background-color: #f5f4f4;
  }
  .pn_cont span:hover {
    cursor: pointer;
    background-color: #f1f1f1;
    border-radius: 2px;
  }
  .photo:hover {
    filter: brightness(1.1);
  }
}

@media only screen and (max-width: 700px) {
  .my_zar,
  .my_zar2 {
    left: 0px;
  }

  .small {
    max-width: 200px;
    max-height: 200px;
  }
  .mid {
    max-width: 200px;
    max-height: 200px;
  }
  .big {
    max-width: 200px;
    max-height: 200px;
  }

  .cont_spacer {
    width: 100%;
    border-bottom: dotted 1px var(--color_sep);
  }

  .photo {
    border-radius: 10px;
    /* border-top-right-radius: 10px; */
    /* border-bottom-right-radius: 10px; */
  }

  .price {
    border-radius: 4px;
  }

  .time_since {
    border-radius: 5px;
  }

  .small,
  .mid,
  .big {
    /* border-radius: 5px; */
  }

  .image_total {
    border-top-right-radius: 10px;
  }

  .newly_listed_cont .cont_spec {
    padding-left: 10px;
  }

  .photo.spo {
    max-width: 100% !important;
  }
  .cont_zar_list {
    padding: 1vh 8px;
  }
}
</style>
