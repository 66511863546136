<template>
  <div class="app" id="app">
    <main-header />
    <div class="cont_back left" v-if="this.selCat && this.selCat.id != 11" @click="goBack()" :style="{ width: calcWidth + 'px' }"></div>
    <div class="left_back" v-if="this.selCat && this.selCat.id != 11" :style="{ display: calcWidth < 50 ? 'none' : 'block' }" @click="goBack()"><ui-icon name="i-arrow-left" size="xxxl" /></div>
    <main-banner v-if="false" />
    <div class="content" :class="{ dynamic_width: $route.meta.dynaWidth && scr > 3 }">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive == 'full'" :key="$route.fullPath"></router-view>
        <!-- <router-view v-if="$route.meta.keepAlive == 'full'" :key="$route.fullPath"></router-view> -->
        <router-view v-if="$route.meta.keepAlive == 'base'"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
      <!-- <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath"></router-view> -->
    </div>
    <main-footer />
    <main-navi-scroll v-if="true" />
    <v-dialog />
    <!-- <main-return-button /> -->
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
//KEEP ALIVE SETTING HAMAAGUI BITGII OROLDOOROI!!! WEBIIG UZEH TUSAM UDASHRAH LAGTAH. ZAR UZCHIHEED BUTSAH TOVCH DARAH UED FILTERDSEN LISTRUU SHUUD RESET HIIGDEH ZEREG BUG ILERCH BAISAN

import mainFooter from "@/comp/main/footer.vue";
import mainHeader from "@/comp/main/header.vue";
import mainNaviScroll from "@/comp/main/naviScroll.vue";
import mainBanner from "@/comp/main/banner.vue";
// import mainReturnButton from "@/comp/main/returnButton.vue";
import "./assets/js/custom.js";

// bug handle
const messages = [
  `Uncaught NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.`, // chromium based
  `NotFoundError: The object can not be found here.`, // safari
  `DOMException: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.`,
];

export default {
  components: {
    mainFooter,
    mainHeader,
    mainNaviScroll,
    mainBanner,
    // mainReturnButton,
  },
  name: "App",
  metaInfo() {
    return {
      title: this.$store.state.appName + " - Зарын цогц систем",
      meta: [
        // { name: "description", content: "Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work." },
        // { property: "og:title", content: "Epiloge - Build your network in your field of interest" },
        // { property: "og:site_name", content: "Epiloge" },
        // { property: "og:type", content: "website" },
        // { name: "robots", content: "index,follow" },
      ],
    };
  },
  data() {
    return {
      title: "gmb",
      deferredPrompt: null,
    };
  },
  methods: {
    scrollLock(bool) {
      let element = document.getElementById("html");
      // console.log("triggered scrollLock :", bool);
      // let body = document.querySelector("body");
      if (bool) {
        element.style.overflowY = "hidden";
        // element.style.touchAction = "none";
        // element.style.position = "fixed";
        // element.style.top = `-${window.scrollY}px`;

        // document.getElementById("html").style.touchAction = "none";
        // document.getElementById("html").style.height = "100%";
        // document.getElementById("body").style.overflow = "hidden";
        // document.getElementById("body").style.position = "fixed";
      } else {
        element.style.overflowY = "scroll";
        // element.style.touchAction = "auto";
        // element.style.position = "";
        // element.style.top = "";

        // document.getElementById("html").style.touchAction = "auto";
        // document.getElementById("html").style.height = "100%";
        // document.getElementById("body").style.overflowy = "scroll";
        // document.getElementById("body").style.position = "relative";
      }
    },
    goBack() {
      if (this.$route.name == "home" && this.selCat && this.selCat.id != 11) {
        this.selCat = { id: 11, name: "Нүүр" };
        // this.$router.push({ name: "home" });
        return;
      }
      // if (this.$route.name == "zarAdd" || this.$route.name == "userHome") this.$router.push({ name: "home" });
      if (this.routeFrom == "zarView") this.$router.push({ name: "home" });
      // if (this.homeUrl) history.replaceState(null, null, this.homeUrl);
      else this.$router.go(-1); //showing error
    },
    preventDefault(e) {
      e = e || window.event;
      if (e.preventDefault) e.preventDefault();
      e.returnValue = false;
    },
    handleResize() {
      this.$store.commit("swidth", window.innerWidth);
    },
    // async fetchFavs() {
    //   await this.$store.dispatch("user/fetchUserFavs");
    // },
    updateFavs() {
      let localFavs = this.logged ? this.getLocal("user").favs : [];
      this.$store.commit("user/favSet", localFavs);
    },
    updateViewHistory() {
      let localHistory = this.getLocal("viewHistory") ?? [];
      this.$store.commit("user/viewHistory", localHistory);
    },
    cleanOldCache() {
      let cache = this.getLocal("cacheItem") ?? [];
      let cacheLatest = cache.filter((e) => Date.now() - e.date < 1000 * 60);
      this.saveLocal("cacheItem", cacheLatest);
    },
    fetchInitialData() {
      this.axios.get("/utility/config/getConfigs").then((res) => {
        if (res.data.length) this.$store.commit("configs", Object.assign({}, ...res.data.map((m) => ({ [m.name]: m.param }))));
        // if (res.data.userInfo) this.$store.commit("auth/userInfo", res.data.userInfo);
      });

      this.axios.get("/zar/cat/getData").then((res) => {
        if (res.data) this.$store.commit("zar/catData", res.data);
      });

      this.axios.get("/utility/location/getNearestLoc").then((res) => {
        if (res.data) this.$store.commit("nearestLoc", res.data);
      });

      // if (this.user) this.$store.dispatch("user/updateUserInfo");

      if (this.user) {
        this.$store.dispatch("user/updateUserNoti");
        setTimeout(() => this.$store.dispatch("user/updateUserNoti"), 1000 * 60);
      }

      // this.axios.get("/zar/object/getAllPropInfo").then((res) => {
      //   if (res.data) this.$store.commit("zar/pinfo", res.data);
      // });

      // this.$store.commit("catData", this.axiosGet("/zar/cat/getData", []));
      // if (this.getLocal("catData")) {
      //   this.axios.get("/zar/cat/getData").then((res) => {
      //     if (res.data) this.saveLocal("catData", res.data);
      //   });
      // } else {
      //   let catData = await this.axiosGet("/zar/cat/getData", []);
      //   this.saveLocal("catData", catData);
      // }
      // if (this.getLocal("pinfo")) {
      //   this.axios.get("/zar/object/getAllPropInfo").then((res) => {
      //     if (res.data.length) this.saveLocal("pinfo", res.data);
      //   });
      // } else {
      //   let pinfo = await this.axiosGet("/zar/object/getAllPropInfo", []);
      //   this.saveLocal("pinfo", pinfo);
      // }
      // if (this.getLocal("configs")) {
      //   this.axios.get("/utility/config/getConfigs").then((res) => {
      //     if (res.data.length) this.$store.commit("configs", Object.assign({}, ...res.data.map((m) => ({ [m.name]: m.param }))));
      //   });
      // } else {
      //   let configs = await this.axiosGet("/utility/config/getConfigs", [this.$route.fullPath]);
      //   this.$store.commit("configs", Object.assign({}, ...configs.map((m) => ({ [m.name]: m.param }))));
      // }
      //
      // let pinfo = await this.axiosGet("/zar/object/getAllPropInfo", [this.$route.fullPath]);
      // this.$store.commit("configs", Object.assign({}, ...configs.map((m) => ({ [m.name]: m.param }))));
      // this.saveLocal("pinfo", pinfo);
      // });
      // this.axios.get("/zar/object/getAllPropInfo").then((res) => {
      //   if (res.data.length) this.saveLocal("pinfo", res.data);
      // });
      // if (configs.length) this.$store.commit("configs", Object.assign({}, ...configs.map((m) => ({ [m.name]: m.param }))));
    },
    checkAutoLogin() {
      let query = this.$route.query.login;
      if (query && query.length == 14) {
        let phone = query.substr(0, 8);
        let pass = query.substr(8, 14);
        this.$bus.emit("openModal", { name: "userLogin", dataSet: { phone, pass, returnPage: "/user/home" } });
      }
    },
    // installPWA() {
    //   if (deferredPrompt) {
    //     deferredPrompt
    //       .prompt()

    //       .then((userChoice) => {
    //         if (userChoice.outcome === "accepted") {
    //           console.log("User accepted the install prompt.");
    //         } else {
    //           console.log("User dismissed the install prompt.");
    //         }

    //         deferredPrompt = null; // Reset the deferredPrompt variable
    //       })

    //       .catch((error) => {
    //         console.log("Error occurred while prompting for installation:", error);

    //         deferredPrompt = null; // Reset the deferredPrompt variable
    //       });
    //   } else if (!window.matchMedia("(display-mode: standalone)").matches) {
    //     // The app is already installed

    //     alert("You have already installed the app.");
    //   } else {
    //     // The app is uninstalled

    //     deferredPrompt = null; // Reset the deferredPrompt variable
    //   }
    // },
  },
  computed: {
    selCat: {
      get() {
        return this.$store.state.selCat;
      },
      set(ob) {
        this.$store.commit("selCat", ob);
      },
    },
    calcWidth() {
      let calc = (this.$store.state.swidth - 1300) / 2;
      return calc > 0 ? calc - 30 : 0;
    },
    scr() {
      return this.$store.getters.scr;
    },
    user() {
      return this.logged ? this.$store.state.auth.user : false;
    },
    logged() {
      return this.$store.state.auth.status.logged;
    },
    routeFrom() {
      return this.$store.state.routeFrom;
    },
    openDrawers() {
      return this.$store.state.openDrawers;
    },
    openModals() {
      return this.$store.state.openModals;
    },
  },
  watch: {
    openDrawers: {
      immediate: true,
      handler() {
        if (this.openDrawers.length) this.scrollLock(true);
        else this.scrollLock(false);
      },
    },
    openModals: {
      immediate: true,
      handler() {
        if (this.openModals.length) this.scrollLock(true);
        else this.scrollLock(false);
      },
    },
    logged: {
      immediate: true,
      handler() {
        // this.$bus.emit("updateViewHistory");
        this.updateFavs();
        // this.updateViewHistory();

        if (this.logged) this.$store.dispatch("user/updateUserInfo");
        else this.$store.commit("user/updateUserInfoResponse", null);
      },
    },
  },
  mounted() {
    this.checkAutoLogin();
    // this.$bus.on("updateViewHistory", this.updateViewHistory);
    // await this.fetchFavs();
    this.updateFavs();
    this.updateViewHistory();

    if (typeof window !== "undefined") {
      window.addEventListener("error", (ev) => {
        if (messages.includes(ev.message)) {
          ev.preventDefault();
          window.location.reload();
        }
      });
    }
    //let deferredPrompt; // Declare the deferredPrompt variable in the global scope

    // Check if the browser supports service workers and the \beforeinstallprompt` event`

    // window.addEventListener(
    //   "popstate",
    //   function() {
    //     // The popstate event is fired each time when the current history entry changes.

    //     var r = confirm("You pressed a Back button! Are you sure?!");

    //     if (r == true) {
    //       // Call Back button programmatically as per user confirmation.
    //       history.back();
    //       // Uncomment below line to redirect to the previous page instead.
    //       // window.location = document.referrer // Note: IE11 is not supporting this.
    //     } else {
    //       // Stay on the current page.
    //       history.pushState(null, null, window.location.pathname);
    //     }

    //     history.pushState(null, null, window.location.pathname);
    //   },
    //   false
    // );

    if ("serviceWorker" in navigator && "BeforeInstallPromptEvent" in window) {
      window.addEventListener("beforeinstallprompt", (event) => {
        event.preventDefault();

        this.deferredPrompt = event;

        // Show the FAB when the install prompt is triggered

        const installFab = document.getElementById("installFab");

        installFab.style.display = "block";
      });
    }

    // this.lockScroll(false);
    // this.cleanOldCache();

    //DISABLES BACK BUTTON ACTION ON MOBILE
    // if (this.isMobile) {
    //   history.pushState(null, document.title, location.href);
    //   window.addEventListener("popstate", function() {
    //     history.pushState(null, document.title, location.href);
    //   });
    // }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.fetchInitialData();
    if (this.isMobile)
      window.onbeforeunload = function(evt) {
        var message = "Та апп-аас гарахдаа итгэлтэй байн уу?";
        if (typeof evt == "undefined") {
          evt = window.event;
        }
        if (evt) {
          evt.returnValue = message;
        }

        return message;
      };
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
#app {
  /* max-width: 100% !important; */
  width: 1300px;
  /* margin-left: auto !important;
  margin-right: auto !important; */
  /* height: 100vh !important; */
}

.dynamic_width {
  /* max-width: 100% !important; */
  width: min-content !important;
  min-width: 1000px !important;
  max-width: 1300px !important;
}

.content {
  /* padding: 0 10px !important; */
  margin-left: auto !important;
  margin-right: auto !important;
}
.sticky {
  position: sticky;
  top: 0;
  z-index: 100;
}
.cont_back.right {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-100px);
}
.cont_back.left {
  position: fixed;
  left: 0;
  top: 0;
  width: 20vh;
  height: 100%;
  background-color: #f1f1f1;
  opacity: 0.5;
  transition: all 300ms;
}
.cont_back.left:hover,
.cont_back.left:hover + .left_back {
  opacity: 1;
  cursor: pointer;
}
.cont_back.left div,
.left_back {
  position: fixed;
  left: 25px;
  top: 25px;
  font-weight: var(--base_weight);
  font-stretch: 95%;
  opacity: 0.3;
  transition: all 300ms;
  pointer-events: none;
}
.cont_back.left div {
  left: 50px;
  top: 27px;
}
.left_back:hover {
  cursor: pointer;
}
.back_icon {
  opacity: 0.2;
}
.back_icon:hover {
  opacity: 1;
  cursor: pointer;
}
@media only screen and (max-width: 700px) {
  /* .content {
    padding-top: 58px;
  } */
}
@media only screen and (min-width: 701px) {
  .content {
    padding: 0 10px !important;
  }
}
</style>
