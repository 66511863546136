<template>
  <div class="cont_zar_filter_input">
    <div class="pob_name">{{ pob.name }}</div>
    <div v-if="name" class="ml-4">
      {{ name }} <span v-if="pob.unit">{{ pob.unit }}</span>
    </div>
    <ui-input :key="inputKey" class="ml-3 mt-1" :minWidth="12" v-on:typed="typed($event)" :autoType="autoType" :unit="pob.unit" :autoFocus="false" emitEnter :unmask="false" :pat="'[9][9][9][9][-][9][9][9][9]'" :active="active" />
    <div v-if="pob.unit == 'он'" class="guide ml-3">Жишээ нь: -2010, 2010-2016, 2018-, 2013</div>
    <div v-else class="guide ml-3">Жишээ нь: -5, 5-8, 5-, 5</div>
    <div class="guide ml-3">Enter дарна уу</div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiInput from "@/comp/ui/input.vue";

export default {
  name: "zar-filter-input",
  props: { pob: Object, filter: Array },
  components: {
    uiInput,
  },
  data() {
    return {
      min: 0,
      max: 999999,
      name: "",
      active: false,
      inputKey: 0,
    };
  },
  methods: {
    reset() {
      this.name = "";
      this.active = false;
    },
    typed(text) {
      if (text == "") {
        this.$emit("clearItem", this.submitted);
        return;
      }
      let data = this.explode(text, "-");
      if (data.length == 1) {
        // this.min = Number(data[0]);
        // let dn = this.digitNumber(this.min);
        // this.max = this.min + (dn == 1 ? 0.9 : dn == 2 ? 9.9 : 99.9);
        // this.name = "~" + this.min;
        this.min = Number(data[0]);
        this.max = Number(data[0]);
        this.name = this.min;
      } else {
        let from = data[0] == "";
        let to = data[1] == "";
        this.min = from ? 0 : Number(data[0]);
        this.max = to ? 999999 : Number(data[1]);

        if (from && !to) this.name = this.max + " хүртэл";
        if (!from && to) this.name = this.min + " дээш";
        if (!from && !to) this.name = this.min + "-" + this.max;
      }

      this.$emit("clickedItem", { name: this.name, min: this.min, max: this.max, text });
      this.active = true;
    },
  },
  watch: {
    items() {},
    pob: {
      deep: true,
      handler() {
        if (!this.pob.sel.first().selected) {
          this.inputKey++;
          this.reset();
        }
      },
    },
  },
  computed: {
    submitted() {
      return this.filter.find((e) => e.prop == this.pob.id);
    },
    autoType() {
      let autoType = this.pob.sel.first().autoType;
      return autoType ?? null;
    },
  },
  mounted() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_zar_filter_input {
  text-align: left;
  display: block;
  padding: 0;
  margin-bottom: 20px;
  width: fit-content;
  transition: all 150ms;
}

.pob_name {
  padding: 0px 0px 0px 0px;
  /* margin-bottom: 2px; */
  color: var(--color_head);
  font-weight: 600;

  display: block;
  transition: all 0.2s;
  width: fit-content;
  opacity: 1;
  /* text-transform: uppercase; */
  font-size: 13px;
}
</style>
