<template>
  <div>
    <div v-if="emp && pob.active" class="cont_zar_filter_sub" :class="{ emp: emp && pob.active, [pob.ptype_name + '_prop']: true, first: contInd == 0, main: pob.main, child, under: pob.under, no_padding: sbar }">
      <!-- <div v-else :id="sbar && 'sbar'" :class="{ sbar, noscroll: actItems < 9 }" class="cont_item " :style="{ height: calcHeight }"> -->
      <div>
        <div class="pob_name" :class="{ setBlack: mOver, [pob.ptype_name + '_pname']: true }">{{ pob.name }}<span v-if="haveChilds" class="have_child">&#10095;</span></div>
        <div :id="sbar && 'sbar'" :class="{ sbar, noscroll: actItems < maxItem }">
          <ui-reflow>
            <template v-for="(i, ind) in items_with_com">
              <div
                v-if="showAll || ind < maxItem"
                :key="'p' + pob.id + 'i' + i.id + 'n' + ind"
                class="item"
                :class="{ last_common_item: ind + 1 == items_com.length, [pob.ptype_name + '_item']: true, setBlack: mOver, disabled: singleItem, selected: i.selected, com: i.com, empty: i.znum == 0, other: last ? (last.prop.id == pob.id ? true : false) : false }"
                @click="i.znum != 0 && clickedItem(i.id, i.name)"
              >
                <ui-icon v-if="i.icon && i.icon != 'empty'" class="icon" :name="i.icon" size="l" />
                {{ i.name }}
                {{ pob.pname }} {{ pob.unit }}
                <span v-if="i.znum != '999999'" class="count">{{ i.znum }}</span>
                <img v-if="false && i.selected" class="selected_icon" src="/icon/i-close3.svg" />
              </div>
              <slot :name="'psel' + i.id" />
              <slot :name="'under' + i.id" />
            </template>
            <div v-if="items_with_com_znum.length >= maxItem" class="more link ml-1" @click="showAll = !showAll">
              <ui-icon name="i-arrow-thick" size="s" :angle="!showAll ? 'rev' : ''" /> <b>{{ showAll ? "хураах" : "цааш..." }}</b>
            </div>
          </ui-reflow>
        </div>
      </div>
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "zar-filter-sub",
  props: { child: Boolean, items: Array, pob: Object, last: Object, contInd: Number, haveChilds: Boolean, filLength: Number, zarTotal: Number, mOver: Boolean },

  data() {
    return {
      selected: "all",
      showAll: false,
      maxItem: 9,
    };
  },
  methods: {
    trigger() {
      this.$refs["select" + this.pob.id].focus();
      //document.getElementById("select" + this.pob.id).focus();
    },
    clickedItem(id, name) {
      if (id != "sep") {
        if (this.singleItem) return;
        // this.$bus.emit("clickedFilterItem", { pob: this.pob, item: this.items.find((e) => e.id == id) });
        this.$emit("clickedItem", { id, name });
      }
    },
    sort() {
      this.items.sort((a, b) => a.znum.localeCompare(b.znum));
    },
  },
  watch: {
    items() {
      //console.log(this.items);
      //let found = this.items.find((e) => e.selected);
      ///console.log(found);
    },
    selected() {
      this.clickedItem(this.selected.id, this.selected.name);
    },
  },
  computed: {
    // calcHeight() {
    //   return this.haveChilds ? "auto" : 21 * this.items_with_com.filter((i) => i.znum).length + "px";
    // },
    singleItem() {
      let itemAct = this.pob.sel.filter((s) => s.znum);
      if (itemAct.length == 1 && itemAct[0].znum < this.zarTotal) return false;
      return !this.pob.main && !this.haveChilds && itemAct.length == 1 && this.pob.id != (this.last && this.last.prop.id) && !this.pob.sel.find((s) => s.selected);
    },
    itemSelected() {
      return this.items.find((e) => e.selected);
    },
    emp() {
      return this.items.find((s) => s.znum > 0);
    },
    actItems() {
      return this.items.filter((s) => s.znum > 0).length;
    },
    // items_com() {
    //   return this.items.filter((e) => e.com);
    // },
    items_com() {
      return this.sortArr(
        this.items.filter((e) => e.com && e.znum),
        "znum",
        2
      );
    },
    items_with_com() {
      return this.items_com.concat(this.items);
    },
    items_with_com_znum() {
      return this.items_com.concat(this.items).filter((e) => e.znum);
    },
    items_with_znum() {
      return this.items.filter((e) => e.znum);
    },
    scr() {
      return this.$store.getters.scr;
    },
    // items_with_com() {
    //   return this.items_com.concat(this.items.filter((e) => !e.com));
    // },
    // items_with_com() {
    //   return this.items_com.concat(this.items);
    // },
    sbar() {
      return this.items.filter((i) => i.znum).length > 12 || this.scr == 1 ? true : false;
    },
  },
  mounted() {
    // console.log(this.pob);
    //console.log(this.$refs);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_zar_filter_sub {
  text-align: left;
  display: block;
  margin-bottom: 15px;
  width: fit-content;
  transition: all 150ms;
  /* padding: 5px 10px; */
}
.cont_zar_filter_sub.emp {
  /* display: none; */
}
.cont_zar_filter_sub.main {
  border-radius: 10px;
  padding: 2px 5px;
  background-color: #f3f3f3;
}
.cont_zar_filter_sub.model_prop {
  background-color: #f3f3f3;
}

.cont_item {
  /* overflow: hidden !important; */
  transition: all 200ms;
}
.model_item.item,
.town_item.item {
  /* text-transform: uppercase; */
  /* letter-spacing: 0.02em; */
  /* font-family: "Open Sans"; */
  /* height: 21px;
  font-size: 12px; */

  /* font-family: "Raleway"; */
  /* font-family: "Fira Sans", sans-serif; */
}

.main .item {
  /* color: #000; */
  animation: none;
  opacity: 1;
  font-size: 14px !important;
}
.main .pob_name {
  opacity: 1;
  /* font-size: var(--15px); */
  font-weight: 600;
  font-size: 13px !important;
  /* text-transform: uppercase; */
  filter: saturate(2.5);
}
.main .child .pob_name {
  font-size: 12px !important;
}
.no_padding {
  padding: 0 !important;
  background: none !important;
}

/*
.first {
}
.first .sbar {
}
*/
/* .setBlack {
  opacity: 1 !important;
  font-size: 14px;
} */
.hide {
  display: none;
}
.other {
  color: var(--color_link) !important;
  /* background-color: #f2fbff; */
}
.other:hover {
  background-color: #ebf6fc !important;
}
.other.selected {
  border-radius: 2px;
}
.child.selected {
  padding: 0 2px;
}
.child {
  margin: 0 0 2px 4px;
  padding-left: 6px;
  border-left: solid 1px rgb(226, 226, 226);
}
.child .item {
  /* padding: 0; */
  padding-left: 5px;
  font-size: 13px !important;
}

.child .pob_name {
  /* font-weight: 550 !important; */
  font-size: 10px !important;
  padding: 3px 0 0 0 !important;
  margin: 0 !important;
}

.pob_name {
  padding: 0px 0px 0px 0px;
  /* margin-bottom: 2px; */
  color: var(--color_head);
  font-weight: 600;

  display: block;
  transition: all 0.2s;
  width: fit-content;
  opacity: 1;
  /* text-transform: uppercase; */
  font-size: 13px;
  /* font-family: finland, serif; */

  /* letter-spacing: 0.02em; */

  /* border-bottom: dotted 1px #ccc; */
  /* background-color: #fafafa !important; */
  /* font-stretch: 120%; */
  /* letter-spacing: -0.03em; */
  /* text-transform: uppercase; */
  /* font-family: "Raleway"; */
  /* font-family: "Bold"; */
  /* transform: scale(1.05); */
  /*font-family: "Fira Sans", sans-serif; */
  /* font-family: "Roboto Slab", serif; */
  /* font-family: "Raleway", sans-serif; */
  /* font-family: "Nunito Sans"; */
}

/*
.pob_name::before {
  content: ".";
  font-size: 14px;
  font-weight: 700;
  color: #a8a8a8;
  position: relative;
  top: -3px;
  left: -4px;
} */

.pob_name.model_pname,
.pob_name.town_pname {
  /* text-transform: uppercase; */
  /* text-shadow: 1px 1px 3px #ffffad; */
}

.item {
  display: block;
  padding: 0.15em 0.26em;
  border-radius: 2px;
  text-transform: none;
  /* height: 19px; */
  /* overflow: hidden; */
  /* margin: 0; */
  /* opacity: 0.8; */
  /* font-size: 13px; */
  /* transition-property: opacity, color, height, transform, font; */
  /* transition-duration: 150ms, 300ms, 200ms, 100ms, 50ms; */
  /* transition-timing-function: ease; */
  color: var(--body_color2);
  /* font-size: 13px; */
  /* margin-bottom: 1px; */
  position: relative;
  line-height: 1.1 !important;
  /* width: 100% !important; */
  /* background-color: aquamarine; */
}

.item:has(img) {
  padding-left: 20px;
}

/* .model_item.item::first-letter {

} */
/* .model_item.item:not(.com)::first-letter {
  font-weight: 480;
} */

.empty {
  /* height: 0; */
  /* transform: scale(0.8); */
  /* opacity: 0.5; */
  display: none;
}
.empty img {
  opacity: 0.2;
}
.empty span {
  /* transition: color 200ms;
  color: #fff;
  display: none; */
}

.disabled,
.disabled span {
  opacity: 0.6s;
  color: #6e6e6e !important;
}
.disabled:hover {
  cursor: text !important;
  background-color: transparent !important;
}
.icon {
  position: absolute;
  margin-left: -18px;
  top: 1px;
  /* vertical-align: middle; */
}

.box {
  display: block;
  width: fit-content;
  border-radius: 1px;
  text-align: left;
  display: block;
  padding: 0px;
  margin-block-start: 0;
  margin-block-end: 0;
  /* overflow: hidden; */
}
.com {
  /* background: rgb(255, 216, 248); */
  /* background: linear-gradient(90deg, rgb(234, 255, 255) 0%, rgba(255, 255, 255, 1) 100%); */
  /* background: linear-gradient(90deg, rgb(236, 255, 234) 0%, rgba(255, 255, 255, 0) 100%); */
  /* text-shadow: -1px 0 #c9ffff, 0 3px #e2ffff, 4px 0 #e2ffff, 0 -5px #e2ffff; */
  font-weight: 500;
  /* color: #000; */
  /* background-color: rgb(233, 225, 216); */
  /* background-color: rgb(239, 255, 254); */
  /* text-transform: uppercase;
  font-stretch: 120%; */
}

.com .count {
  font-weight: var(--base_weight) !important;
  background-color: #ececec;
  color: #5e5e5e;
  /* border: solid 1px #ccc; */
  padding: 2px 3px 1px 3px;
  border-radius: 3px;
  /* box-shadow: 1px 1px 1px #d3d3d3; */
}

.sbar {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 210px;
  margin-top: 2px;
  border: solid 1px #dadada;
  border-radius: 7px;
  padding: 2px 4px 2px 4px !important;
  /* --tw-shadow: 0 1px 1px 0 rgb(0 0 0 / 0.1), 0 1px 1px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow); */
  background-color: #fff;
}

.noscroll {
  overflow-x: hidden;
  overflow-y: auto;
}

.main .sbar {
  max-height: 300px;

  /*  border: solid 1px #e2e2e2;*/
  /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1); */
  padding: 2px 5px;
  /* border-radius: 6px; */
}

.model_item.selected,
.town_item.selected {
  /* font-weight: 600 !important; */
}

.selected {
  font-style: inherit;
  border-radius: 1px;
  /* font-weight: 600 !important; */
  background-color: var(--color_selected);
  /* transition: all 250ms; */
  /* font-family: "Bold" !important; */
}

.item .count,
.selected .count {
  font-size: 10px !important;
  /* color: #797979 !important; */
  transition: opacity 0.5s;
  position: relative;
  font-weight: 300;
  left: 0px;
  top: -1px;
}
.selected .count {
  color: #474747;
}
.selected_icon {
  position: relative;
  top: 1px;
  left: 5px;
  max-height: 11px;
  max-width: 11px;
  padding-right: 5px;
  /* filter: invert(83%) sepia(0%) saturate(7498%) hue-rotate(12deg) brightness(89%) contrast(76%); */
  transition: all 500ms;
  animation: fade_in 200ms;
  animation-iteration-count: 1;
}
.selected:hover {
  background-color: var(--color_selected) !important;
}

.last_common_item {
  border-bottom: dashed 1px #acacac;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

/* .count::before {
  content: "(";
  margin: 0 2px 0 1px;
  font-size: 7px;
  position: relative;
  top: -1px;
  color: #f1f1f1;
  line-height: 16px;
}
.count::after {
  content: ")";
  margin: 0 1px 0 2px;
  font-size: 7px;
  position: relative;
  top: -1px;
  color: #f1f1f1;
  line-height: 16px;
} */

.selected span::before,
.selected span::after {
  color: #000000;
}

.cont_select {
  position: relative;
}
.button {
  min-width: 80px;
}
.select {
  z-index: 10;
  opacity: 0;
  top: 0px;
  position: absolute;
}
.under .empty {
  display: none;
}

.under .item {
  /* height: 19px; */
  /* font-size: 13px; */
}

/* .fade .item.empty {
  transition: all 0.4s ease-out;
  opacity: 0;
}

.fade .item {
  opacity: 1;
} */

.seperator {
  color: #ccc;
} /*
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 500ms;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(50px, 0);
  font-size: 0px;
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(50px, 0);
  font-size: 0px;
}*/

.list-complete-item {
  transition: all 150ms;
  display: block;
}
.list-complete-enter
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(10px);
}

.list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-10px);
}

.list-complete-leave-active {
  opacity: 0;
  position: absolute;
}

.have_child {
  position: relative;
  top: 0px;
  left: 5px;
  font-weight: 700;
  font-size: 11px;
  transform: rotate(90deg);
  /* filter: saturate(0); */
}

@keyframes fade_in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 100;
    transform: scale(1);
  }
}

@media only screen and (min-width: 701px) {
  .main .item:hover {
    background-color: #e2e2e2;
  }
  .item:hover {
    background-color: #e2e2e2;
    cursor: pointer;
  }
  .sbar .item:hover {
    background-color: #f1f1f1;
  }
  .empty:hover {
    cursor: default;
    background-color: #fff;
  }
}
</style>
