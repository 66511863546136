<template>
  <div class="cont_main" :class="{ scrolling_bottom: scrollDirection == 1 && scr == 1, scrolling_top: scrollDirection == 2 && scr == 1 }">
    <main-server-message />
    <div id="header" class="cont_header" :class="{ align_center: scr < 4 }">
      <span v-if="scr < 3 && $route.name == 'home'" @click="showMenu('cat')" class="menu_icon_left"><img src="/icon/i-menu2.svg" height="24"/></span>
      <span v-if="scr == 1 && $route.name == 'userHome'" @click="showMenu('userInfo')" class="menu_icon_left"><img src="/icon/i-user-id.svg" height="24"/></span>
      <span v-if="scr == 1 && logged && $route.name == 'userHome'" @click="$bus.emit('userLogout')" class="menu_icon_right"><img src="/icon/i-logout3.svg" height="24"/></span>
      <!-- <span v-if="scr < 2 && $route.name == 'home' && selObj" @click="showMenu('fil')" class="menu_icon_left menu_icon_filter"><img src="/icon/i-filter.svg" height="18"/></span> -->
      <span @click="showHome()" class="logo" :class="{ logo_inactive: $route.name == 'zarView' }">
        <!--blink_me: gLoading -->
        <!--<span class="main">zaraa</span>-->
        <img class="text" :src="'/icon/i-logo.svg'" height="28" /><!--img class="image" :src="'/icon/i-logo-glasses.svg'" height="45" /-->
      </span>
      <main-navi v-if="scr == 4" />
    </div>
    <div class="header_hr"></div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import mainNavi from "@/comp/main/navi.vue";
import mainServerMessage from "@/comp/main/serverMessage.vue";

export default {
  name: "main-header",
  components: { mainNavi, mainServerMessage },
  data() {
    return {
      favsChanged: false,
      collectChanged: false,
    };
  },
  methods: {
    zarAdd() {
      if (this.$route.name == "zarAdd") this.$router.go();
      else this.$router.push({ name: "zarAdd" });
    },
    viewCollected() {
      let col = this.collect.first();
      this.$bus.emit("viewZar", { obj: col.obj, zid: col.zid, direct: true });
    },
    showHome() {
      this.$bus.emit("showHome");
    },
    showMenu(name) {
      this.$store.commit("showMenu", { [name]: true });
    },
  },
  watch: {
    collect() {
      this.collectChanged = true;
      setTimeout(() => (this.collectChanged = false), 500);
    },
  },
  computed: {
    scrollDirection() {
      return this.$store.state.scrollDirection;
    },
    scr() {
      return this.$store.getters.scr;
    },
    selObj: {
      get() {
        return this.$store.state.selObj;
      },
      set(val) {
        this.$store.commit("selObj", val);
      },
    },
    gLoading() {
      return this.$store.state.gLoading;
    },
    collect() {
      return this.$store.state.user.collect;
    },
    logged() {
      return this.$store.state.auth.status.logged;
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_main {
  /* margin-bottom: 20px; */
  transition: all 500ms;
  opacity: 1;
  margin-bottom: min(2vw, 15px);

  /* padding: 0 10px !important; */
}
.cont_main.scrolling_bottom {
  /* opacity: 0; */
  /* margin-top: -50px; */
}
.cont_main.scrolling_top {
  opacity: 1;
}
.cont_header {
  text-align: center;
  /* padding: 15px 0; */
  /* height: 20px; */
  position: relative;
  top: 0;
  height: 50px;
}
.cont_header img {
  filter: invert(22%) sepia(14%) saturate(979%) hue-rotate(177deg) brightness(94%) contrast(88%);
}
.align_center {
  text-align: center;
}
.menu_icon_left {
  position: absolute;
  vertical-align: middle;
  margin-top: 5px;
  padding: 10px 10px;
  left: 0px;
  /* background-color: #00b7ff; */
  /* filter: invert(83%) sepia(1%) saturate(26%) hue-rotate(315deg) brightness(70%) contrast(108%); */
}
.menu_icon_right {
  position: absolute;
  right: 1px;
  vertical-align: middle;
  margin-top: 3px;
  padding: 11px;
  /* background-color: aqua; */
  /* filter: invert(83%) sepia(1%) saturate(26%) hue-rotate(315deg) brightness(70%) contrast(108%); */
}
.menu_icon_filter {
  margin-left: 40px;
  margin-top: 8px;
}
.right {
  float: right;
}
.right > div {
  margin-right: 10px;
}
.logo {
  user-select: none;
  border-radius: 4px;
  transition: all 150ms;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
}

.logo .image {
  /* filter: invert(16%) sepia(19%) saturate(1754%) hue-rotate(160deg) brightness(94%) contrast(92%); */
  filter: none;
  margin-top: 6px;

  /* filter: invert(38%) sepia(51%) saturate(1723%) hue-rotate(192deg) brightness(84%) contrast(90%); */
  /* filter: invert(22%) sepia(45%) saturate(526%) hue-rotate(180deg) brightness(99%) contrast(83%); */
  /* filter: invert(41%) sepia(16%) saturate(2639%) hue-rotate(177deg) brightness(81%) contrast(108%); */
  /* filter: invert(27%) sepia(74%) saturate(389%) hue-rotate(179deg) brightness(95%) contrast(89%); */
}

.logo .text {
  position: relative;
  top: 10px;
  opacity: 0.8;
  filter: none;
  left: -3px;
  opacity: 0.8;
  /*filter: invert(26%) sepia(21%) saturate(1196%) hue-rotate(182deg) brightness(85%) contrast(81%);*/
  /* display: none; */
  /* filter: invert(53%) sepia(48%) saturate(390%) hue-rotate(157deg) brightness(88%) contrast(89%);
  filter: invert(33%) sepia(83%) saturate(4621%) hue-rotate(196deg) brightness(98%) contrast(101%); */
  /* filter: invert(31%) sepia(91%) saturate(1074%) hue-rotate(184deg) brightness(91%) contrast(87%); */
  /* filter: invert(28%) sepia(91%) saturate(1344%) hue-rotate(199deg) brightness(83%) contrast(95%); */
}

.logo_inactive {
  filter: saturate(0);
  opacity: 0.6;
  transition: all 1s;
}
.logo:hover {
  opacity: 1;
  cursor: pointer;
}

/* .logo:hover .first_part {
  transform: rotate(-5deg) scale(1.05);
} */

.logo:active {
  background: none;
}

.logo .main {
  position: relative;
  display: inline-block;
  top: -12px;
  font-size: 34px;
  font-weight: 600;
  /* color: #3f597f; */
  /* font-stretch: 120%; */
  /* color: var(--color_logo); */
  letter-spacing: -0.03em;
  /* font-family: cairo, sans-serif; */
}
.logo .main::first-letter {
  font-size: 30px;
  font-weight: 700;
}

.header_hr {
  content: "";
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  width: 800px;
  height: 1px;
  background-color: var(--color_sep);
  z-index: -1;
}

@media only screen and (min-width: 1150px) {
}

@media only screen and (max-width: 700px) {
  .cont_main {
    /* margin-bottom: 20px; 
    position: sticky; WORKING BUT LIST PAGE SCROLL TOP CONTENT UNDER HEADER ISSUE
    top: 0;*/
    width: calc(100% + 5px);
    /* background-color: var(--color_blue_dark); */

    /* z-index: 100; */
    /* background-color: var(--color_blue_dark); */
    /* position: fixed !important; */
    /* top: 0px !important; */
    /* border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px; */
  }

  .logo .text {
    /* filter: invert(96%) sepia(0%) saturate(18%) hue-rotate(258deg) brightness(104%) contrast(104%); */
  }

  .cont_main.scrolling_bottom {
    top: -50px !important;
  }

  .logo {
    margin-top: -2px;
  }

  .header_hr {
    width: 100%;
    /* display: none; */
  }

  .cont_header img {
    /* filter: invert(96%) sepia(0%) saturate(18%) hue-rotate(258deg) brightness(104%) contrast(104%); */

    /* filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(198deg) brightness(103%) contrast(101%); */
  }

  .logo img {
    /* filter: none; */
    /* filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(198deg) brightness(103%) contrast(101%); */
  }
}

@media only screen and (max-width: 699px) {
  /* .cont_main {
    margin-bottom: 0px;
  } */
}
@media only screen and (min-width: 700px) {
  .cont_main {
    /* margin-bottom: 15px; */
  }
}
</style>
